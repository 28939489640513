import * as PIXI from "pixi.js";
import { useControls } from "leva";

import { useEffect, useRef, useState } from "react";
import { ColorMapFilter } from "@pixi/filter-color-map";
import { BlurFilter } from "@pixi/filter-blur";
import { AdjustmentFilter } from "@pixi/filter-adjustment";

import {
  UploadOutlined,
  SaveOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";

import { useMount } from "react-use";

import { Stage } from "./pixi/stage/";
import { Sprite } from "./pixi/components/Sprite.js";
import { Container } from "./pixi/components/Container.js";

import { PreCompose } from "./pixi/components/PreCompose.js";
import { Composition } from "./pixi/components/Composition.js";

import { Filter } from "./pixi/filters/Filter.js";

import { NoiseMap } from "./NoiseMap.js";

function lerp(start, end, t) {
  return start * (1 - t) + end * t;
}

export const Logo = ({
  src,
  animate,
  noiseSpeed,
  noiseScale,
  fxBleed,
  position,
  invert,
  shortLogo = false,
  randomise = () => {},
  downloadable = false,
  ...rest
}) => {
  const [hasDisplacement, setHasDisplacement] = useState(false);

  const mousePos = useRef();

  const apiRef = useRef();
  const el = useRef();
  const pill = useRef();

  const perlinTex = useRef();
  const [hasPerlin, setHasPerlin] = useState(false);

  const displacement = useRef();
  const bubble = useRef();
  const bubbleSlow = useRef();

  const blurRef = useRef();

  useMount(() => {
    el.current.addEventListener("mousemove", (ev) => {
      if (bubble.current && bubbleSlow.current) {
        mousePos.current = { x: ev.offsetX, y: ev.offsetY };
      }
    });
  });

  let maxLogoWidth = shortLogo ? 300 : 1000;

  let defaultW = Math.min(maxLogoWidth, window.innerWidth);

  // Allow larger max width ln high DPI small screens
  if (window.devicePixelRatio > 2 && shortLogo) defaultW *= 1.5;

  const aspect = shortLogo ? 200 / 200 : 200 / 700;
  const defaultH = defaultW * aspect;
  const WIDTH = rest.width || defaultW;
  const HEIGHT = rest.height || defaultH;

  let bleedScale = WIDTH * 0.004;

  if (WIDTH < 600) bleedScale *= 0.5;
  if (WIDTH < 600) noiseSpeed *= 3;
  if (WIDTH < 600) noiseScale *= 0.5;

  let maskW = WIDTH * 1.5;
  let maskDiff = maskW - WIDTH;
  maskDiff *= 0.5;

  let maskPos = {
    x: WIDTH * -0.25 + maskDiff * position.x,
    y: WIDTH * -0.25 + maskDiff * position.y,
  };

  const LOGO_GRAPHIC = shortLogo ? "/logo-short.png" : "/logo-base.png";

  const actualNoiseScale = shortLogo ? noiseScale * 2.5 : noiseScale;
  const actualNoiseSpeed = shortLogo ? noiseSpeed * 0.5 : noiseSpeed;
  const glowDist = shortLogo ? 1 : 1;

  return (
    <div
      id="logo"
      className={shortLogo ? "Logo--short" : "Logo--full"}
      style={{ minHeight: `100vh`, background: invert ? `#000000` : `#EEEEEE` }}
    >
      <div>
        <div id="view" style={{ position: "relative" }}>
          <NoiseMap
            onInit={(el) => {
              perlinTex.current = new PIXI.Texture.from(el);
              setHasPerlin(true);
            }}
            onFrame={() => {
              if (perlinTex.current) {
                perlinTex.current.update();
                if (mousePos.current && bubble.current && bubbleSlow.current) {
                  bubble.current.x = lerp(
                    bubble.current.x,
                    mousePos.current.x,
                    0.1
                  );
                  bubble.current.y = lerp(
                    bubble.current.y,
                    mousePos.current.y,
                    0.1
                  );

                  bubbleSlow.current.x = lerp(
                    bubbleSlow.current.x,
                    mousePos.current.x,
                    0.05
                  );
                  bubbleSlow.current.y = lerp(
                    bubbleSlow.current.y,
                    mousePos.current.y,
                    0.05
                  );
                } else {
                  //console.log(mousePos, bubble.current, bubbleSlow.current);
                }
              } else {
              }
            }}
            speed={animate ? actualNoiseSpeed : 0}
            scale={actualNoiseScale}
            width={250}
            height={250}
            monochrome={true}
            style={{ display: "none" }}
          />

          {downloadable && (
            <FloatButton
              style={{ right: 94 }}
              onClick={() => apiRef.current.downloadFrame()}
              icon={<SaveOutlined />}
            />
          )}
          {downloadable && (
            <FloatButton
              style={{ right: 40 }}
              onClick={() => randomise()}
              icon={<ReloadOutlined />}
            />
          )}

          <div id="render" ref={el}>
            {hasPerlin && (
              <Stage
                key={`logo-${shortLogo ? "short" : "full"}`}
                width={WIDTH}
                height={HEIGHT}
                debug={false}
                onInit={(api) => {
                  apiRef.current = api;
                }}
              >
                <PreCompose
                  name="noisemask"
                  width={WIDTH}
                  height={HEIGHT}
                  animate={true}
                >
                  <Sprite
                    id="noisetex"
                    width={maskW}
                    height={maskW}
                    texture={perlinTex.current}
                    scale={1}
                    zIndex={1}
                    anchor={0}
                    x={maskPos.x}
                    y={maskPos.y}
                  />

                  <Sprite
                    width={WIDTH * 0.2}
                    height={WIDTH * 0.2}
                    alpha={0.5}
                    ref={(ref) => {
                      if (ref) {
                        ref.anchor.set(0.5);
                        // Position off screen for non-mouse screens
                        ref.x = -999;
                        ref.y = -999;
                        bubble.current = ref;
                      }
                    }}
                    image={"/radial-gradient.png"}
                    tint={0x222222}
                    zIndex={3}
                  />
                  <Sprite
                    width={WIDTH * 0.4}
                    height={WIDTH * 0.4}
                    alpha={0.5}
                    ref={(ref) => {
                      if (ref) {
                        ref.anchor.set(0.5);
                        // Position off screen for non-mouse screens
                        ref.x = -999;
                        ref.y = -999;
                        bubbleSlow.current = ref;
                      }
                    }}
                    image={"/radial-gradient.png"}
                    tint={0x444444}
                    zIndex={2}
                  />

                  <Filter
                    type="adjustment"
                    ref={(ref) => {
                      if (ref) {
                        ref.contrast = 5;
                        ref.saturation = 1.5;
                        ref.alpha = 1;
                        ref.gamma = 1;
                      }
                    }}
                  />
                </PreCompose>

                <PreCompose name="stage" animate={true}>
                  <Container>
                    <Sprite
                      width={WIDTH}
                      height={WIDTH}
                      ref={(ref) => {
                        if (ref) {
                          ref.anchor.set(0.5);
                          ref.x = WIDTH / 2;
                          ref.y = HEIGHT / 2;
                        }
                      }}
                      image={"/pixel-solid.png"}
                      tint={invert ? 0xffffff : 0xeeeeee}
                      alpha={invert ? 1 : 0}
                    />

                    <Sprite
                      width={WIDTH}
                      height={WIDTH}
                      ref={(ref) => {
                        if (ref) {
                          ref.anchor.set(0.5);
                          ref.x = WIDTH / 2;
                          ref.y = HEIGHT / 2;
                        }
                      }}
                      image={LOGO_GRAPHIC}
                    />
                    <Container>
                      <Composition scale={1} from="noisemask" zIndex={1} mask />
                      <Sprite
                        width={WIDTH}
                        height={WIDTH}
                        ref={(ref) => {
                          if (ref) {
                            ref.anchor.set(0.5);
                            ref.x = WIDTH / 2;
                            ref.y = HEIGHT / 2;
                          }
                        }}
                        image={LOGO_GRAPHIC}
                      />
                      <Filter
                        type="blur"
                        quality={6}
                        strength={shortLogo ? 10 : 2 * bleedScale}
                      />
                      <Filter
                        type="adjustment"
                        ref={(ref) => {
                          if (ref) {
                            ref.contrast = 10;
                            ref.saturation = 1;
                            ref.alpha = 1;
                            ref.gamma = 0;
                          }
                        }}
                      />
                      <Filter
                        type="glow"
                        distance={1}
                        outerStrength={
                          shortLogo
                            ? fxBleed * bleedScale * 2
                            : fxBleed * bleedScale * 0.5
                        }
                      />

                      {/*<Filter type="noise" noise={0.1} seed={2} />*/}
                    </Container>
                  </Container>
                </PreCompose>

                <Container>
                  <Composition from="stage" scale={1} zIndex={2} />
                  {/*<Composition
                    from="noisemask"
                    scale={1}
                    zIndex={3}
                    alpha={0}
                  />*/}
                  {invert && <Filter type="invert" />}
                </Container>
              </Stage>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
