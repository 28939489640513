import React, {
	useRef,
	useState,
	useEffect,
	useImperativeHandle,
	useMemo,
} from "react";
import * as PIXI from "pixi.js";

import { withPixiApp } from "../stage/provider";
import { useMount, useUnmount } from "react-use";
import { If } from "../utils/If.js";

import { Container, ContainerChildren } from "./Container";

import { useAnimationFrame } from "../../useAnimationFrame";

// Manually add other blend modes as filters eg shaders:
// https://godotshaders.com/snippet/blending-modes/

// Or off screen canvas blending 2 precomposed layers?

function PixiPreCompose(props, ref) {
	const [mounted, setMounted] = useState(false);
	const _ref = useRef();
	const interval = useRef();

	const existingcomp = props.api.getComp(props.name);
	const renderTexture =
		typeof existingcomp !== "undefined"
			? existingcomp
			: PIXI.RenderTexture.create({
					width: props.width
						? props.width
						: props.app.renderer._view.screen.width,
					height: props.height
						? props.height
						: props.app.renderer._view.screen.height,
					resolution: window.devicePixelRatio,
					//multisample: PIXI.MSAA_QUALITY.LOW,
			  });

	if (typeof existingcomp === "undefined") {
		props.api.setComp(props.name, renderTexture);
	}

	useImperativeHandle(ref, () => {
		return _ref.current;
	});

	useMount(() => {
		// Precomposed layers are hidden
		_ref.current.visible = false;
		captureComposition();
		setMounted(true);
	});

	useUnmount(() => {});

	useAnimationFrame(() => {
		if (props.animate) {
			captureComposition();
		}
	});

	function captureComposition() {
		const renderer = props.app.renderer;

		_ref.current.visible = true;
		renderer.render(_ref.current, { renderTexture });
		_ref.current.visible = false;

		//console.log("render", renderTexture);
		//renderTexture.update();
		//props.api.setComp(props.name, renderTexture);
	}

	return (
		<Container ref={_ref}>
			<If cond={mounted}>
				<ContainerChildren root={_ref.current}>
					{props.children}
				</ContainerChildren>
			</If>
		</Container>
	);
}

const PreCompose = withPixiApp(React.forwardRef(PixiPreCompose));

export { PreCompose };
