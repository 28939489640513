import * as PIXI from "pixi.js";
import { useControls } from "leva";

import { useEffect, useRef, useState } from "react";
import { ColorMapFilter } from "@pixi/filter-color-map";
import { BlurFilter } from "@pixi/filter-blur";
import { AdjustmentFilter } from "@pixi/filter-adjustment";

import {
  UploadOutlined,
  SaveOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";

import { useMount } from "react-use";

import { Stage } from "./pixi/stage/";
import { Sprite } from "./pixi/components/Sprite.js";
import { Container } from "./pixi/components/Container.js";

import { PreCompose } from "./pixi/components/PreCompose.js";
import { Composition } from "./pixi/components/Composition.js";

import { Filter } from "./pixi/filters/Filter.js";

import { Logo } from "./Logo.js";

export const RenderPixi = ({ src, ...rest }) => {
  const [
    {
      animate,
      noiseSpeed,
      noiseScale,
      fxBleed,
      position,
      invert,
      hires,
      shortLogo,
    },
    set,
  ] = useControls(() => ({
    animate: {
      value: true,
      label: "Animate",
    },
    noiseSpeed: {
      label: "Speed",
      value: 0.1,
      min: 0.05,
      max: 0.2,
      step: 0.01,
    },
    noiseScale: {
      label: "Scale",
      value: 0.2,
      min: 0.05,
      max: 1,
      step: 0.01,
    },
    /*noiseSize: {
      label: "Size",
      value: 120,
      min: 60,
      max: 240,
      step: 1,
    },*/
    fxBleed: {
      label: "Bleed",
      value: 10,
      min: 5,
      max: 50,
      step: 1,
    },
    position: {
      label: "Position",
      value: { x: 0.5, y: 0.5 },
      min: 0,
      max: 1,
    },
    invert: {
      value: true,
      label: "Invert Colour",
    },
    hires: {
      value: false,
      label: "Hi-Res (1080p)",
    },
    shortLogo: {
      value: false,
      label: "Short Logo",
    },
  }));

  const randomise = () => {
    function rand(min, max) {
      return Math.random() * (max - min) + min;
    }

    set({
      animate: false,
      noiseScale: rand(0.05, 1),
      fxBleed: rand(5, 50),
      position: { x: rand(0, 1), y: rand(0, 1) },
    });
  };

  const w = hires ? 1080 : null;
  const h = hires ? 1080 * (200 / 700) : null;

  return (
    <Logo
      key={`hires-${JSON.stringify(hires)}`}
      width={w}
      height={h}
      animate={animate}
      noiseSpeed={noiseSpeed}
      noiseScale={noiseScale}
      fxBleed={fxBleed}
      position={position}
      invert={invert}
      randomise={() => randomise()}
      downloadable={true}
      shortLogo={shortLogo}
    />
  );
};
