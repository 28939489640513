import React, { useEffect, useState, useRef } from "react";
import { useStore, Store, StoreRouter } from "../store";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";

import { useBodyClass } from "../hooks/useBodyClass.js";

import GenerativeLogoApp from "../generative/App";

export const Generator = ({ children }) => {
  return <GenerativeLogoApp />;
};
