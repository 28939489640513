import React, { useEffect, useState } from "react";

export const MatchMedia = ({ query, children }) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const formattedQuery = formatMediaQuery(query);
    const mediaQuery = window.matchMedia(formattedQuery);

    const handleMediaChange = (e) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    setMatches(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [query]);

  const formatMediaQuery = (query) => {
    if (!query.includes("(") && !query.includes(")")) {
      return `(${query})`;
    }
    return query;
  };

  return matches ? children : null;
};

export default MatchMedia;
