import React, { useRef, useState, useEffect, useImperativeHandle } from "react";
import * as PIXI from "pixi.js";

import { withPixiApp } from "../stage/provider";
import { useMount, useUnmount } from "react-use";
import { If } from "../utils/If.js";

import { AdjustmentFilter } from "@pixi/filter-adjustment";
import { GlowFilter } from "@pixi/filter-glow";
import { ConvolutionFilter } from "@pixi/filter-convolution";

function initFilter(props) {
	let f;

	switch (props.type) {
		case "displacement":
			let sprite = props.sprite || new PIXI.Sprite();
			let scale = props.scale || 50;
			f = new PIXI.filters.DisplacementFilter(sprite);
			f.scale.set(scale);
			return f;
			break;
		case "noise":
			let noise = props.noise || 0.5;
			let seed = props.seed || undefined;
			f = new PIXI.filters.NoiseFilter(noise, seed);
			return f;
			break;
		case "adjustment":
			f = new AdjustmentFilter({
				gamma: 1.055,
				saturation: 1.245,
				contrast: 4.49,
			});
			return f;
			break;
		case "glow":
			f = new GlowFilter({
				distance: props.distance || 0,
				outerStrength: props.outerStrength || 0,
				innerStrength: 0,
				color: 0x000000,
				quality: 50,
			});
			return f;
			break;
		case "convolution":
			let matrix = [0, 0, 0, 0, 0, 0.5, 1, 1, 1];
			let width = 20;
			let height = 0;
			f = new ConvolutionFilter(matrix, width, height);
			return f;
			break;
		case "blur":
			let strength = props.strength || 8;
			let quality = props.quality || 10;
			let resolution = props.resolution || 2;
			let kernelSize = props.kernelSize || 11;

			f = new PIXI.filters.BlurFilter(
				strength,
				quality,
				resolution,
				kernelSize
			);

			return f;
			break;
		case "invert":
			f = new PIXI.filters.ColorMatrixFilter();

			// Set the matrix values for inverting colors
			f.negative();

			return f;
			break;
	}
}

function PixiFilter(props, ref) {
	const [mounted, setMounted] = useState(false);

	const _ref = useRef();

	useImperativeHandle(ref, () => {
		return _ref.current;
	});

	useMount(() => {
		let f = initFilter(props);
		_ref.current = f;
		props.root.filters.push(f);

		setMounted(true);
	});

	useUnmount(() => {
		if (!_ref.current) return;
		if (!props.root.filters) return;
		const indexToRemove = props.root.filters.indexOf(_ref.current);

		// Check if the item exists in the array
		if (indexToRemove !== -1) {
			// Remove the item using splice
			props.root.filters.splice(indexToRemove, 1);
		}
	});

	useEffect(() => {
		if (props.type === "glow") {
			_ref.current.outerStrength = props.outerStrength;
			_ref.current.distance = props.distance;
		}
	}, [props.outerStrength, props.distance]);

	return <></>;
}

const Filter = withPixiApp(React.forwardRef(PixiFilter));

export { Filter };
