import { useState, useRef } from "react";
import {
  UploadOutlined,
  SaveOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import { CanvasFileUploader } from "./CanvasFileUploader.js";
import { RenderImage } from "./RenderImage.js";
import { NoiseMap } from "./NoiseMap.js";
import { If } from "./If.js";
import FPSStats from "react-fps-stats";

import "antd/dist/reset.css";

import { FloatButton } from "antd";
import "./styles.css";

const props: UploadProps = {
  multiple: true,
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
  showUploadList: false,
};

export default function App() {
  const [imgUrl, setImgUrl] = useState(null);
  const donwloadLink = useRef(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  return (
    <div className="App">
      {/*<FPSStats />*/}

      <RenderImage
        src={imgUrl}
        onRender={(downloadUrl) => {
          let link = document.createElement("a");
          link.href = downloadUrl;
          link.download = `${Date.now()}.png`;
          link.innerHTML = "Download";
          link.id = "download";

          donwloadLink.current = link;

          setDownloadUrl(downloadUrl);
        }}
      />
    </div>
  );
}
