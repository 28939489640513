import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/*

Local

*/

import { useBodyClass } from "./hooks/useBodyClass.js";
import { Presence } from "./components/Presence.js";
import { TouchClass } from "./components/IsTouch.js";
import { AnimatedText } from "./components/AnimatedText.js";
import { MatchMedia } from "./components/MatchMedia.js";

/*

Generative

*/

import { Logo } from "./generative/Logo.js";

/*

Store

*/

import { useStore, Store, StoreRouter } from "./store";
import { routes } from "./store/transformer.js";

/*

Pages

*/

import { Basic } from "./pages/Basic.js";
import { Generator } from "./pages/Generator.js";

/*

Styles

*/

import "./App.scss";

/*

Assets

*/

function BodyClass({ className }) {
  useBodyClass(className);

  return <div />;
}

function Meta({ data }) {
  if (!data) return null;

  let matchKey = Object.keys(data)
    .filter((k) => window.location.href.match(k))
    .pop();
  if (!matchKey) return null;

  let metaData = data[matchKey];

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
    </Helmet>
  );
}

function App() {
  return (
    <Presence isVisible={true}>
      <TouchClass>
        <StoreRouter routes={routes}>
          <Store>
            {(store) => {
              if (store.GENERATOR) return <Generator />;
              return (
                <div>
                  <div className="Site-strapline">
                    <Link to={"/"}>Studio Omelette</Link>
                  </div>

                  <div className="Site-nav">
                    <ul className="List List--nav">
                      <li>
                        <Link to={store.SHOWINFO ? "/" : "/about/"}>
                          <AnimatedText
                            text={store.SHOWINFO ? "Close" : "About"}
                          />
                        </Link>
                      </li>
                      <li>
                        <a href="mailto:info@studiomelette.com">
                          <MatchMedia query="max-width:700px">Email</MatchMedia>
                          <MatchMedia query="min-width:701px">
                            info@studiomelette.com
                          </MatchMedia>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="nofollow"
                          href="https://www.instagram.com/studio_omelette"
                        >
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </div>

                  <BodyClass className={`Body--page`} />

                  {/* Canvas */}
                  <div className="Site-canvas">
                    <MatchMedia query="max-width:700px">
                      <Logo
                        animate={true}
                        noiseSpeed={0.1}
                        noiseScale={0.2}
                        fxBleed={20}
                        position={{ x: 0.5, y: 0.5 }}
                        invert={true}
                        shortLogo={true}
                      />
                    </MatchMedia>
                    <MatchMedia query="min-width:701px">
                      <Logo
                        animate={true}
                        noiseSpeed={0.1}
                        noiseScale={0.2}
                        fxBleed={20}
                        position={{ x: 0.5, y: 0.5 }}
                        invert={true}
                        shortLogo={false}
                      />
                    </MatchMedia>
                  </div>
                  {/* End Canvas */}

                  <Presence
                    className="Layer Layer--project"
                    isVisible={store.SHOWINFO}
                  >
                    <Basic>
                      <div className="Page-story">
                        <div>
                          <div className="Content Content--story">
                            <p>
                              Studio Omelette is a London-based independent
                              creative studio founded by{" "}
                              <a
                                target="_blank"
                                rel="nofollow"
                                href="https://www.linkedin.com/in/cécile-dumetier-97b99048"
                              >
                                Cécile Dumetier
                              </a>
                              .
                            </p>
                            <p>
                              Specifically not specialised, the studio focuses
                              on crafting brands' stories in a unique way
                              through different techniques based on open
                              exploration and research.
                            </p>
                            <p>
                              Studio Omelette has a small, nimble structure to
                              maintain flexibility and foster a culture of
                              collaboration with a diverse team of independent
                              creative talents.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Basic>
                  </Presence>
                </div>
              );
            }}
          </Store>
        </StoreRouter>
      </TouchClass>
    </Presence>
  );
}

export default App;
