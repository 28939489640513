import React, { useEffect, useState, useRef } from "react";
import { useStore, Store, StoreRouter } from "../store";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";

import { motion, useElementScroll } from "framer-motion";

import { useBodyClass } from "../hooks/useBodyClass.js";

export const Basic = ({ children }) => {
  let history = useHistory();
  const completed = useRef(false);
  const ref = useRef();
  const { scrollY } = useElementScroll(ref);
  const [opacity, setOpacity] = useState(1);

  const screenRef = useRef();
  const [blackout, setBlackout] = useState(1);

  //const ACTIVE_TILE = useStore(state => state.ACTIVE_TILE);

  const HISTORY = useStore((state) => state.HISTORY);
  const store = useStore((state) => state);

  let previousListingPage = HISTORY[0];
  let backTo = "/";

  scrollY.onChange(() => {
    // Heading disappears after 200px scroll
    let o = 1 - scrollY.current / 200;
    if (o < 0) o = 0;

    if (o !== opacity) setOpacity(o);

    let maxScroll = ref.current.scrollHeight - ref.current.clientHeight;
    let startAt = maxScroll - screenRef.current.offsetHeight;

    startAt += 0;

    let dist = maxScroll - startAt;

    if (scrollY.current >= startAt) {
      let blackoutProgress = (scrollY.current - startAt) / dist;
      let b = 1 - blackoutProgress;

      if (b !== blackout) setBlackout(b);

      // On complete...
      if (blackoutProgress >= 0.98 && !completed.current) {
        // Update the ref
        completed.current = true;
        // Go back a page
        history.push(backTo);
      }
    }
  });

  return (
    <div className="Page">
      <div className="Page-scroll" ref={ref}>
        <div className="Page-paper">{children}</div>
        <div
          className="Page-blackout"
          style={{ opacity: blackout }}
          ref={screenRef}
        />
      </div>
    </div>
  );
};
