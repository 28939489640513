import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

export const AnimatedText = ({ text }) => {
  const curText = useRef("");
  const transitions = useRef({ in: null, out: null });
  const [transitionText, setTransitionText] = useState(transitions.current);
  const [inText, setInText] = useState("");
  const [outText, setOutText] = useState("");

  const letterIn = {
    hidden: { opacity: 0, translateY: 5 },
    visible: { opacity: 1, translateY: 0 },
  };
  const letterOut = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    //transitions.current.out = transitions.current.in;
    //transitions.current.in = text;

    //setTransitionText(transitions.current);
    setInText(text);
    setOutText(curText.current);
    curText.current = text;
  }, [text]);

  if (!inText) return null;

  function strToArr(str) {
    let out = [];
    for (var i = 0; i < str.length; i++) {
      out.push(str[i].replace(/ /g, "\u00a0"));
    }
    return out;
  }

  return (
    <div style={{ position: "relative" }}>
      <div key={inText} style={{ whiteSpace: "nowrap" }}>
        {strToArr(inText).map((i, ix) => (
          <motion.span
            key={`in${ix}`}
            style={{ display: "inline-block" }}
            transition={{ delay: Math.min(ix * 0.04, 0.2) }}
            variants={letterIn}
            initial={"hidden"}
            animate={"visible"}
          >
            {i}
          </motion.span>
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          whiteSpace: "nowrap",
        }}
        key={outText}
      >
        {strToArr(outText).map((i, ix) => (
          <motion.span
            key={`out${ix}`}
            style={{ display: "inline-block" }}
            transition={{ duration: 0.2, delay: ix * 0.01 }}
            variants={letterOut}
            initial={"visible"}
            animate={"hidden"}
          >
            {i}
          </motion.span>
        ))}
      </div>
    </div>
  );
};
